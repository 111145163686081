/*
| Developed by Starton
| Filename : casl-context.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { createContextualCan } from '@casl/react'
import { defineAbility, PureAbility } from '@casl/ability'
import { AppAbility } from '@/services/casl/casl-ability'

/*
|--------------------------------------------------------------------------
| Context
|--------------------------------------------------------------------------
*/
export const AbilityContext = React.createContext<AppAbility>(new PureAbility())
export const Can = createContextualCan(AbilityContext.Consumer)

/*
|--------------------------------------------------------------------------
| Provider
|--------------------------------------------------------------------------
*/
export const AbilityProvider = ({ ability, children }: { ability: AppAbility; children: React.ReactNode }) => {
	return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}

/*
|--------------------------------------------------------------------------
| Hooks
|--------------------------------------------------------------------------
*/
export const useAbility = () => {
	const ability = React.useContext(AbilityContext)
	if (!ability) {
		throw new Error('useAbility must be used within an AbilityProvider')
	}
	return ability
}
