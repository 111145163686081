/*
| Developed by Starton
| Filename : NavContainer.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

'use client'

import React from 'react'
import { AppContainer, AppWrapper, GlobalActionType, Nav, Navigation, useGlobalContext } from '@starton/design-system'
import { ArrowDownToDotIcon, HomeIcon, NewspaperIcon, PanelsTopLeft, Send, SettingsIcon } from 'lucide-react'
import { UrlsConfig } from '@/config/urls.config'
import { NavUser } from '@/components/layouts/nav'
import { useAbility } from '@/services/casl/casl-context'
import { useSessionMe } from '@/modules/auth/api/session.hooks'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const NavContainer = () => {
	const { dispatch } = useGlobalContext()
	const ability = useAbility()
	const { data: session } = useSessionMe()

	// Build top menu items based on permissions
	// ---------------------------------------------------------------------------
	const topMenuItems: Navigation = React.useMemo(() => {
		// Default items
		const items: Navigation = [
			{
				icon: <HomeIcon />,
				title: 'Dashboard',
				href: UrlsConfig.webapp.dashboard,
			},
			{
				kind: 'header',
				title: 'Projects',
			},
			{
				icon: <PanelsTopLeft />,
				title: 'Projects',
				href: UrlsConfig.webapp.project.list,
			},
			{
				kind: 'header',
				title: 'Products',
			},
			{
				icon: <Send />,
				title: 'Easy Deploy',
				href: UrlsConfig.webapp.product.easyDeploy,
			},
		]

		// Add submit project item if user is connected
		if (ability.can('create', 'Project')) {
			// Insert after Projects list item
			items.splice(3, 0, {
				icon: <ArrowDownToDotIcon />,
				title: 'Submit a project',
				href: UrlsConfig.webapp.project.submit,
			})
		}

		return items
	}, [ability])

	// Build bottom menu items based on permissions
	// ---------------------------------------------------------------------------
	const bottomMenuItems: Navigation = React.useMemo(() => {
		// Default items
		const items: Navigation = [
			{
				isExternal: true,
				icon: <NewspaperIcon />,
				title: 'Blog',
				href: 'https://blog.starton.com',
			},
		]

		// Add settings item if user has manage permission
		if (ability.can('manage', 'all')) {
			items.push({
				icon: <SettingsIcon />,
				title: 'Paramètres',
				href: UrlsConfig.webapp.settings,
			})
		}

		// Return items
		return items
	}, [ability])

	// Set navigation
	// ---------------------------------------------------------------------------
	React.useEffect(() => {
		dispatch({
			type: GlobalActionType.SET_NAVIGATION,
			payload: { topMenuItems, bottomMenuItems, afterBottomMenuItems: <NavUser /> },
		})
	}, [bottomMenuItems, topMenuItems, dispatch, session])

	// Render
	// ---------------------------------------------------------------------------
	return <Nav />
}
