/*
| Developed by Starton
| Filename : urls.config.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

export const UrlsConfig = {
	domain: process.env.HOST,
	dirupt: 'https://dirupt.com',
	auth: {
		login: '/auth/login',
		register: '/auth/register',
		forgotPassword: '/auth/forgot-password',
		resetPassword: '/auth/reset-password',
		verifyEmail: '/auth/verify-email',
	},
	webapp: {
		dashboard: '/',
		settings: '/settings',
		project: {
			list: '/projects',
			submit: '/projects/submit',
			details: (slug: string) => `/projects/${slug}`,
		},
		product: {
			easyDeploy: '/products/easy-deploy',
		},
	},
	docs: {
		home: '/docs',
		easyDeploy: '/docs/easy-deploy',
	},
}
